




import { Component, Vue, Prop } from 'vue-property-decorator'
import { ContentList } from '@/modules/content/models/index.ts'

@Component({})
export default class MemberFreeTag extends Vue {
  @Prop({ default: {} }) item!: ContentList

  isMemberFree() {
    let item = this.item
    let isFreeDiscount = Number(item.membercard_discount) <= 0  // 免费的折扣
    let isJoin = item.join_membercard || item.allow_membercard_discount  // 是否参与会员卡
    let isNotFree = item.price > 0 || item.lowest_price > 0  // 是否不免费
    return isFreeDiscount && isJoin && isNotFree
  }
}
