

















import { Component, Vue, Prop } from 'vue-property-decorator'
import { Pagination } from 'element-ui'
import * as model from '@/modules/common/models'

@Component({
  components: {
    [Pagination.name]: Pagination
  }
})
export default class DsPagination extends Vue {
  @Prop({ default: 2 }) total!: number
  @Prop({ default: 10 }) pageSize!: number
  @Prop({ default: 7 }) pagerCount!: number
  @Prop({ default: 1 }) currentPage!: number

  onPageChange(val: number) {
    this.$emit('onPageChange', val)
  }
}
