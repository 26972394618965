









import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
})
export default class ContentBox extends Vue {
  @Prop({ default: '暂无内容哦~' }) tips!: string
  @Prop() sizeCls?: string
}
