
































import { Component, Vue, Prop } from 'vue-property-decorator'
import * as model from '@/modules/common/models'
import { utils } from '@/utils/utils'
import { time_utils } from '@/utils/time_utils'
import SearchList from '../pages/search.vue'
import sweepCodeDialog from '@/modules/common/components/sweep-code-dialog.vue';
import activityBar from '@/modules/common/components/activity-bar.vue'
import memberFreeTag from '@/modules/common/components/member-free-tag.vue'
import enterCheck from '@/modules/studio/pages/detail/enterCheck'
import { isSupportType, getContentTypeSn } from '@/config/typeConfig'

@Component({
  components: {
    sweepCodeDialog,
    activityBar,
    memberFreeTag
  }
})
export default class ListSell extends Vue {
  @Prop({ default: {} }) info!: model.ListSell
  @Prop({ default: true }) showType!: boolean
  @Prop({ default: true }) showSubscribe!: boolean
  @Prop({ default: '' }) activity!: string
  @Prop({ default: () => { return {} } }) activityItem?: any

  stateTxt: Array<object> = [
    {key: '未开始', style: 'no_start'},
    {key: '直播中', style: 'liveing'},
    {key: '看回放', style: 'live_end'}
  ]
  dialogOption: object = {
    showDialog: false,
    type: '',
    contentId: '',
    title: '更多精彩内容请访问移动端店铺'
  }

  mounted() {
  }

  get isLive() {
    const info: any = this.info
    const type = info.type
    let isLive = false
    switch (type) {
      case 'live': // 搜索只会搜出 内容直播 只会是 live
      case 'content_live': // 分类或组件 可能是 内容直播 content_live
      case 'class_live': // 分类或组件 可能是 课时直播 class_live
        isLive = true
        break
    }
    return isLive
  }

  get isSupportType() {
    const type = this.info.type
    return isSupportType(type)
  }

  get getImgSrc() {
    return utils.createImgSrc(this.info.indexpic || this.info.live_indexpic, { width: 440 })
  }

  get getInfo() {
    const info: any = this.info
    const type = info.type
    const typeLabel = getContentTypeSn(type)
    const subscribeCount = utils.numberShowRules1(info.subscribe_count)
    const viewCount = utils.numberShowRules1(info.view_count)
    const commentCount = utils.numberShowRules1(info.comment_count)
    const classCount = utils.numberShowRules1(info.class_count)

    let updateTime: any = info.update_time
    if (Number(updateTime)) {
      updateTime = time_utils.formatDate(updateTime, 'yyyy-MM-dd')
    }
    let infoTips = ''
    switch (type) {
      case 'article':
      case 'audio':
      case 'video':
        infoTips = `${this.showSubscribe && this.shopShowData.sub ? viewCount + '人在看 | ' : ''}${commentCount}评论${updateTime ? ' | ' + updateTime + '更新' : ''}`
        break
      case 'content_live':
      case 'class_live':
        infoTips = `${time_utils.formatDate(info.start_timestamp)}开播`
        break
      case 'course':
        infoTips = `${classCount}课时${updateTime ? ' | ' + updateTime + '更新' : ''}${this.showSubscribe && this.shopShowData.sub ? ' | ' + subscribeCount + '人在学' : ''}`
        break
      case 'trainingcampclass':
        const start = time_utils.formatDate(info.start_time, 'MM.dd')
        const end = time_utils.formatDate(info.end_time, 'MM.dd')
        infoTips = `开课时间：${start} - ${end}`
        break
      case 'offlinecourse':
        infoTips = `${this.showSubscribe && subscribeCount + '人已报名'}`
        break
      default:
        infoTips = ''
        break
    }
    return infoTips
  }

  get shopShowData() {
    return this.$store.state.shopShowData
  }

  get isShowPrice() {
    let price: number = +this.numPrice // 检查是否为免费商品
    let flag = true
    if (price) {
      flag = this.shopShowData.price
    } else {
      flag = this.shopShowData.free
    }
    return flag || this.activity
  }

  get getInfoPrice() {
    const info = this.info
    let price:any = ''
    if (info.type === 'class_live') {
      price = '需订阅课程'
    } else {
      if (parseFloat(info.lowest_price.toString()) > 0) {
        price = `￥${this.curPrice}`
      } else if (parseFloat(info.highest_price.toString()) > 0) {
        price = `￥${this.curPrice}`
      } else {
        price = '免费'
      }
    }
    return price
  }

  get numPrice() {
    let price: any = ''
    if (this.activity === 'pintuan') {
      price = (this.activityItem.related_skus[0].discount_price / 100).toFixed(2)
    } else if (this.activity === 'limit') {
      price = this.activityItem.limit_price
    } else if (this.info.type === 'offlinecourse') {
      if (this.info.lowest_price === this.info.highest_price) {
        price = this.info.lowest_price.toFixed(2)
      } else {
        price = this.info.highest_price.toFixed(2)
      }
    } else {
      price = this.info.lowest_price
    }
    return price
  }

  get curPrice() {
    let price: any = ''
    if (this.activity === 'pintuan') {
      price = (this.activityItem.related_skus[0].discount_price / 100).toFixed(2)
    } else if (this.activity === 'limit') {
      price = this.activityItem.limit_price
    } else if (this.info.type === 'offlinecourse') {
      if (this.info.lowest_price === this.info.highest_price) {
        price = this.info.lowest_price.toFixed(2)
      } else {
        price = `${this.info.lowest_price.toFixed(2)} -￥${this.info.highest_price.toFixed(2)}`
      }
    } else {
      price = this.info.lowest_price
    }
    return price
  }

  get isShowOriginPrice() {
    if (!isNaN(this.originPrice)) {
      return !!(+this.originPrice)
    } else {
      return false
    }
  }

  get originPrice() {
    let price: any = ''
    if (this.activity === 'pintuan') {
      price = (this.activityItem.related_skus[0].origin_price / 100).toFixed(2)
    } else if (this.activity === 'limit') {
      price = this.activityItem.cost_price
    } else if (this.info.type === 'offlinecourse') {
      if (this.info.original_price === undefined || this.info.highest_original_price === undefined) {
        return
      }
      if (this.info.original_price === this.info.highest_original_price) {
        price = this.info.original_price.toFixed(2)
      } else {
        price = `${(this.info.original_price).toFixed(2)} -￥${this.info.highest_original_price && this.info.highest_original_price.toFixed(2)}`
      }
    } else {
      price = this.info.original_price
    }
    return price
  }

  toDetail() {
    const type = this.info.type
    let name = ''
    switch (type) {
      case 'article':
      case 'audio':
      case 'video':
        this.$router.push({ name: 'content', params: { type, id: this.getContentId() } })
        break
      case undefined:
        this.$router.push({ name: 'columnDetail', params: { id: this.getContentId() } })
        break
      case 'live':
      case 'content_live':
        this.$router.push({ name: 'content', params: { type: 'live', id: this.getContentId() } })
        break
      case 'class_live':
        if (enterCheck(this.info, this, 'course')) {
          this.$router.push({
            name: 'courseStudio',
            params: {
              id: '' + this.info.course_class_id,
              courseId: '' + this.info.course_id
            },
            query: {
              classContentId: this.getContentId()
            }
          })
        }
        break
      case 'course':
        this.$router.push({ name: 'course', params: { id: this.getContentId() } })
        break
      case 'column':
        this.$router.push({ name: 'columnDetail', params: { id: this.getContentId() } })
        break
      case 'offlinecourse':
        this.$router.push({name: 'OfflineDetail', params: { id: this.getContentId() }})
        break
      default:
        this.dialogOption = {
          showDialog: true,
          type: this.info.type,
          contentId: this.getContentId(),
          title: '更多精彩内容请访问移动端店铺'
        }
        break
    }
  }

  getContentId() {
    let info = this.info
    let type = info.type
    let contentId = ''
    switch (type) {
      case 'entity':
        contentId = info.product_id || ''
        break;
      case undefined:
        contentId = info.column_id || ''
        break;
      default:
        contentId = info.content_id
        break;
    }
    return contentId
  }
}
